import React from "react";
import API from "../../../utils/api_auth";
import "./product_block.css";
import SbEditable from "storyblok-react";
import t from "../../../utils/languages";
import Modal from "react-modal";
import "../modal.css";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { hasDocument } from '../../../utils/helper';
import _debounce from 'lodash.debounce';
import { emojiRegx, specialTextRegx } from '../../../utils/regExp';
import MarkDown from "../markdown";
import { lock, unlock } from "../button_play_information/lock";

class ProductBlock extends React.Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;  
    this.state = {
      // 用户信息
      user: {
        firstname : '',
        lastname: '',
        phone: '',
      },
      buyfrom: '', // 购买渠道input框
      errors: {
        firstname: false,
        lastname: false,
        serial: false,
        model: true,
        phone: false,
        buyfrom: false,
        buyproof: false,
        oldFormSerial: true,
      },
      showModal: true,
      models: [],
      // 获取产品的状态 正在加载、加载完成、获取失败
      getProductStatus:'loading',
      products: [],
      newModelId: "",
      newModelSerial: "",
      newModelDate: new Date(),
      isLoading: false,
      isDone: false,
      thankYou: false,
      clickbtn: null,
      newTableFlag: false, // 是否是新表单
      buyproofImage: [], // 图片文件数组
      mouseOver: false,
      uploadingFile: false, // 上传文件loading
      fileUploadDisabled: false, // 禁止上传标志
      uploadErr: false, // 上传文件错误
      fileTypeErr: false, // 文件类型不正确
      serialNumberError: false, // 序列号错误 展示对应提示 type=3
      hasBeenRegistered: false, // 是否已经被注册 type=2
      chinaCommodity: false, // 中国大陆行货 type=0
      isM: false, // 是否是移动端
      isZhCn: false, // 是否是中文
      isLastImage: false, // 是否是最后一张图片
      scollbar: 0,
      canSubmit: {
        buyfrom: false,
        buyproofImage: false,
        newModelSerial: false,
      },
      isReplenishInfo: [],
      needReplenishInfo: false,
      updateProductId: '',
      updateProductSuccess: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.myObserver = null;
    this.imgRef = null;
    this.enableBuyProofUploadProduct = ["162", "167"]
  }
  
  /**
   * 初始化init state
   */
  initFormState(){
    this.setState({
      buyproofImage: [],
    })
  }

  /**
   * 判断是否开放上传凭证附件:
   * 1. 开放语言: 中文
   * 2. 开放产品: 
   * X2D100C: 162
   * cfv100c: 167
   */
  isEnableBuyProofUpload({ productID }) {
    let flag = false;
    
    // 需要中文
    if(!this.state.isZhCn) {
      return flag; 
    }

    // convert productID to string
    if (this.enableBuyProofUploadProduct.includes(productID.toString())) {
      flag = true;
    }

    return flag;
  }


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    // 如果是 x2d 100c，那么就采用新的表单 162
    // 测试环境没有x2d 100c 先使用 115
    if(this.isEnableBuyProofUpload({
      productID: value,
    })) {
      this.setState({ newTableFlag: true });
    } else {
      this.setState({ newTableFlag: false });
    }
    // this.setState({ newTableFlag: true });
    let errors = this.state.errors;

    switch (name) {
      case "newModelId":
        errors.model = !value;
        break;

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
    });

    // this.initFormState();

    /*if (name ===  'model'){
            state.errors.model = !value;
            state.newModelId = value;

        }
        if (name === 'serialNumber'){
            state.errors.serial = value.trim().length <= 5;
            state.newModelSerial = value;         
        }*/
  };

  // 处理 user input 输入
  handleUserChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    let errors = this.state.errors;
    // emoji
    const emojiRegx = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;

    switch (name){
      case 'firstname':
        errors.firstname = emojiRegx.test(value);
        break;
      case 'lastname':
        errors.lastname = emojiRegx.test(value);
        break;
      case 'phone':
        errors.phone = emojiRegx.test(value) || specialTextRegx.test(value);
        break;
      default:
        break;
    }
    let user = this.state.user;
    user[name] = value;
    this.setState({
        errors,
        user: user
    })
  }

  // 处理focus
  handlerRequireFocus = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    let canSubmit = this.state.canSubmit;
    canSubmit[name] = true;
    this.setState({
      canSubmit: canSubmit,
    })
  }

  // 处理常规 input 输入
  handle = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let errors = this.state.errors;
    // emoji
    const emojiRegx 
    = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
    const specialTextRegx = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
    
    switch (name) {
      case "newModelSerial":
        errors.serial = value.trim().length <= 9 || emojiRegx.test(value) || specialTextRegx.test(value);
        errors.oldFormSerial = value.trim().length <= 5;
        break;
      case "buyfrom":
        errors.buyfrom = emojiRegx.test(value) || specialTextRegx.test(value);
        break;
      default:
        break;
    }

    this.setState({
      errors,
      [name]: value,
    });
  };

  // 键盘停下的时候进行正则校验 购买渠道
  handleCheckBuyFrom = (event) => {
    const target = event.target;
    const value = target.value;
    let errors = this.state.errors;
    // emoji
    const emojiRegx 
    = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;
    const specialTextRegx = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
    
    errors.buyfrom = !this.state.buyfrom || emojiRegx.test(value) || specialTextRegx.test(value); // 若校验不符合正则 需要提示错误
    this.setState({
      errors,
    });
  }

  // 校验凭证上传
  handleCheckFile = (event) => {
    const target = event.target;
    const value = target.value;
    let errors = this.state.errors;
    errors.buyproof = !this.isBuyProofImageMatch(); // 若校验不符合正则 需要提示错误
    this.setState({
      errors,
    })
  }

  // 键盘停下的时候进行正则校验
  handleKeyupCheckserial = (event) => {
    const target = event.target;
    const value = target.value;
    let errors = this.state.errors;
    // emoji
    const emojiRegx 
    = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;

    errors.serial = value.trim().length <= 9 || emojiRegx.test(value) || specialTextRegx.test(value);
    this.setState({
      errors,
      serialNumberError: false,
    });
  }

  // 处理图片上传
  handleFileChange = (e) => {
    const { buyproofImage } = this.state;
    const input = e.target;
    const { files } = input;
    const filesArr = Object.values(files);
    let filesArrNew = [...filesArr];
    const userUploadLength = filesArrNew?.length;
    const hasBeenUploadedLength = buyproofImage?.length;

    if (userUploadLength + hasBeenUploadedLength > 2) {
      filesArrNew = filesArr.splice(0, 2 - hasBeenUploadedLength);
    }
    filesArrNew && filesArrNew.forEach(item => {
      this.handleUploadFile(item);
    })
  }

  // 处理图片上传
  handleUploadFile = (file) => {
    if(!file) return;
    // 文件类型不正确 return 并提示错误
    if (file?.type !== 'image/jpeg' && file?.type !== 'image/png') {
      this.setState({ 
        fileTypeErr: true
      })
      return;
    }
    let self = this;
    let canSubmit = this.state.canSubmit;
    canSubmit.buyproofImage = true;
    if(this.state.buyproofImage.length === 1) {
      // 是第三张图片在上传
      this.setState({ isLastImage : true })
    }
    // 设置loading 和 
    this.setState({ 
      canSubmit,
      uploadingFile: true,
      uploadErr: false,
    });

    let api = "/me/uploadfile"
    if(this.state.isZhCn){
      api =  "/me/uploadfile_cn"
    }

    // api接口上传文件
    API.post(api, {
      filename: file?.name,
    })
    .then((res) => {
      // api 返回上传url 和 参数
      const response = res.data.signatureData || res.data.s3data;
      const uploadData = res.data.uploadData;
      const { signatureUrl } = res.data;
      let formData = new FormData();
      const { key: objectKey, bucket } = response.fields
      for (let key in response.fields) {
        formData.append(key, response.fields[key]);
      }
      formData.append('file', file);
      fetch(response.host, {
        method: 'POST',
        mode: 'no-cors',
        body: formData
      })
      .then(function(data){
        self.setState({ uploadingFile: false });
        let host = 'https://cdn.hasselblad.com/';
        let url = host + uploadData.type + '/' + uploadData.filename;
        if(self.state.isZhCn){
          url = signatureUrl;
        }
       
        let buyproofImage = self.state.buyproofImage;
        const newBuyProof = {
          objectKey,
          url,
          bucket,
        }
        buyproofImage.push(newBuyProof);
        // 当数量达到2并且 第3张上传成功时 使input禁用
        self.setState({
          buyproofImage,
        }, () => {
          let errors = self.state.errors;
          if(self.isBuyProofImageMatch()) {
            errors.buyproof = false; 
          }
          self.setState({
            errors,
          });
        })
        self.imgRef.value = '';
        // 达到一定上传数量后 设置不可上传
        if(self.isBuyProofImageMatch()) {
          self.setState({ fileUploadDisabled : true })
        }
      })
      .catch(function(err){
        self.imgRef.value = '';
        let errors = self.state.errors;
        // 第三张图片上传失败
        if(self.state.buyproofImage.length === 1) {
          self.setState({ isLastImage:false })
        }
        errors.buyproof = !self.isBuyProofImageMatch();
        self.setState({ 
          uploadErr : true,
          uploadingFile: false,
          errors,
        })
        console.log('UPLOAD ERROR', err);
      });
    })
    .catch((err) => {
      self.imgRef.value = '';
      let errors = self.state.errors;
      // 第三张图片上传失败
      if(self.state.buyproofImage?.length === 1) {
        self.setState({ isLastImage:false })
      }
      errors.buyproof = !self.state.buyproofImage?.length;
      self.setState({ 
        uploadErr : true,
        uploadingFile: false,
        errors,
      })
      console.log('API FETCH ERROR', err);
    })
  }

  // 文件上传hover
  handleMouseOver = () => {
    this.setState({ mouseOver: true });
  }

  // 文件上传 hover移除
  handleMouseOut = () => {
    this.setState({ mouseOver: false });
  }

  // 处理文件删除
  handleDeleteImg = (targetObjectKey) => {
    let buyproofImage = this.state.buyproofImage;
    let errors = this.state.errors;
    buyproofImage.map((item, index) => {
      if (targetObjectKey === item.objectKey) {
        buyproofImage.splice(index, 1);
      }
    })
    if (buyproofImage.length !== 2) {
      this.setState({
        fileUploadDisabled: false,
        isLastImage: false,
      });
    }
    this.imgRef.value = '';
    // 更新errors状态
    errors.buyproof = !this.isBuyProofImageMatch();
    this.setState({
      errors,
      buyproofImage,
    })
  }

  /**
   * 是否上传凭证图片匹配，数量是否有2张
   * @returns {boolean} 
   */
  isBuyProofImageMatch(){
    const { buyproofImage } = this.state;
    return buyproofImage.length === 2;
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    unlock(this.contentRef);
    this.addNewProduct();
    this.setState({ showModal: false });
    document.documentElement.style.marginRight = null;
  }

  handleAfterOpen(){
    const {scollbar}=this.state;
    lock(this.contentRef);
    document.documentElement.style.marginRight=`${scollbar}px`
}

  addProduct = () => {
    this.setState({ 
      isLoading: true,
      serialNumberError: false,
    });

    const buyproofs = this.state.buyproofImage.map(item => {
      return {
        key: item.objectKey,
        bucket: item.bucket,
      }
    });

    let product = {
      purchased: this.state.newModelDate,
      model: {
        id: this.state.newModelId,
      },
      serial_number: this.state.newModelSerial,
      buyfrom: this.state.buyfrom,
      buyproof: buyproofs,
      user_data: {
        firstname: this.state.user.firstname,
        surname: this.state.user.lastname,
        phone: this.state.user.phone,
      },
      newform: this.state.newTableFlag,
    };
    API.post("/me/products", product)
      .then((res) => {
        switch(res?.data?.type){
          case '3':
            this.setState({ serialNumberError : true });
            break;
          case '2':
            this.setState({ hasBeenRegistered: true });
            break;
          case '1':
            this.setState({ chinaCommodity: false });
            break;
          case '0':
            this.setState({ chinaCommodity: true });
            break;
          default:
            break;
        }
        if(res?.data?.type !== '3') {
          API.get("/me/products").then((response) => {
            this.setState({
              products: response.data,
              thankYou: true,
            });
            this.getButtonType(response.data);
          });
        }
        // 序列号错误也需要设置loading 为false
        this.setState({isLoading: false})
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
      });
  };

  updateProcut = () => {
    this.setState({ 
      isLoading: true,
      serialNumberError: false,
    });

    const buyproofs = this.state.buyproofImage.map(item => {
      return {
        key: item.objectKey,
        bucket: item.bucket,
      }
    });

    let product = {
      purchased: this.state.newModelDate,
      model: {
        id: this.state.newModelId,
      },
      serial_number: this.state.newModelSerial,
      buyfrom: this.state.buyfrom,
      buyproof: buyproofs,
      user_data: {
        firstname: this.state.user.firstname,
        surname: this.state.user.lastname,
        phone: this.state.user.phone,
      },
      newform: this.state.newTableFlag,
      update_product_id: this.state.updateProductId,
    };

    //  请求接口更新数据
    API.post("/me/products/update", product)
    .then((res) => {
      // update success
      if (res.status === 200) {
        API.get("/me/products").then((response) => {
          this.setState({
            products: response.data,
            thankYou: true,
            updateProductSuccess: true,
          });
        });
      }
      this.setState({ isLoading: false });
    })
    .catch((e) => {
      console.log(e);
      this.setState({ 
        isLoading: false,
        thankYou: true,
      });
    });
  }

  /**
   * @param productData
   * 获取产品栏按钮状态 - x2d100c - 162
   * 1.删除产品 2.补充信息
   */
  getButtonType = (productData) => {
    const { isZhCn } = this.state;
    const isReplenishInfo_new = new Array(productData?.length).fill(false);
    productData && productData.map((item, index) => {
      const isReplenishInfo = (item?.product_id === 162 && item?.extended_warranty === 0 && isZhCn && item?.is_cn_sn === 1); // 补充信息
      if (isReplenishInfo) {
        isReplenishInfo_new[index] = true;
      }
      this.setState({ isReplenishInfo: isReplenishInfo_new })
    })
  }

  componentDidMount = () => {
    API.get('/me').then(response => {
      let user = this.state.user;
      user.firstname = response.data.user.firstname;
      user.lastname = response.data.user.surname;
      user.phone = response.data.user.phone;
      this.setState({ user })
    })
    API.get("/me/products")
      .then((response) => {
        this.setState({ products: response.data,getProductStatus:'finished' });
        this.getButtonType(response.data);
      })
      .catch((e) => {
        // 获取产品失败
        this.setState({getProductStatus:'failed'})
        console.log(e);
      });
    API.get("/products")
      .then((response) => {
        var products = _.chain(response.data)
          .groupBy("group_name")
          .map(function(products, group) {
            return {
              name: group,
              products: products,
            };
          })
          .value();

        this.setState({ models: products });
      })
      .catch((e) => {
        console.log(e);
      });
      if (!hasDocument) return;
      const targetDom = document.body;
      this.setState({ scollbar:document.documentElement.scrollWidth - document.documentElement.clientWidth })
      const obj = targetDom.getBoundingClientRect();
      this.setState({
        isM: obj.width < 768,
        isZhCn : this.lang === 'zh-cn',
      })
      this.myObserver = new ResizeObserver(_debounce((entries) => {
        const ele = entries[0];
        if (!ele) return;
        this.setState({
          isM: ele.contentRect.width < 768
        })
      }, 200));
      this.myObserver.observe(targetDom);
  };

  componentWillUnmount() {
    this.myObserver.disconnect();
  }

  getLocalDate = (date) => {
    if (date) {
      var _date = new Date(date);
      var options = { year: "numeric", month: "numeric", day: "numeric" };
      if (navigator.language) {
        return _date.toLocaleDateString(navigator.language, options);
      } else {
        return _date.toLocaleDateString("de-de", options);
      }
    } else {
      return "";
    }
  };

  // reset errors
  resetErrors = () => {
    let errors = this.state.errors;
    errors.firstname = false;
    errors.lastname = false;
    errors.serial = false;
    errors.model = true;
    errors.phone = false;
    errors.buyfrom = false;
    errors.buyproof = false;
    errors.oldFormSerial = true;
    return errors;
  }

  resetCanSubmit = () => {
    let canSubmit = this.state.canSubmit;
    canSubmit.buyproofImage = false;
    canSubmit.buyfrom = false;
    canSubmit.newModelSerial = false;
    return canSubmit;
  }

  addNewProduct = () => {
    const errors = this.resetErrors(); 
    const canSubmit = this.resetCanSubmit();
    this.setState({
      newModelId: "",
      newModelDate: new Date(),
      newModelSerial: "",
      thankYou: false,
      buyfrom: "",
      buyproofImage: [],
      newTableFlag: false,
      errors,
      canSubmit,
      mouseOver: false,
      uploadingFile: false,
      uploadErr: false,
      serialNumberError: false,
      hasBeenRegistered: false,
      chinaCommodity: false,
      fileUploadDisabled: false,
      fileTypeErr: false,
      isLastImage: false,
      needReplenishInfo: false,
      updateProductSuccess: false,
      updateProductId:'',
    });
  };

  // 阻止键盘
  handleDateFocus = () => {
    document.activeElement.blur();
  }

  /**
   * 解绑产品
   * @param {产品id} id
   */
  removeProduct = (id, e, index) => {
    if (this.setState.isLoading) return;
    this.setState({ isLoading: true,clickbtn:index});
    API.delete(`/me/products?id=${id}`)
      .then(() => {
        API.get("/me/products").then((response) => {
          this.setState({ products: response.data, isLoading: false,clickbtn:null});
          this.getButtonType(response.data);
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ isLoading: false });
      });
  };

  /**
   * 补充产品信息
   * @param {产品id} id
   */
  replenishProduct = (p, e , index) => {
    if (this.setState.isLoading) return;
    if(p.buyproof?.length === 2) {
      this.setState({ isLastImage: true })
    }
    
    this.setState({ 
      newTableFlag: true,
      needReplenishInfo: true,
      newModelSerial: p.serial_number,
      buyfrom: p.buyfrom,
      buyproofImage: [...p.buyproof], 
      newModelId: "162",
      updateProductId: p.id,
      newModelDate: new Date(p.purchased),
     })
    this.handleOpenModal();
  }

  // X2D 100C 新表单
  renderNewForm = (serial_nubmer_image, blok) => {
    const { user, buyfrom, errors, needReplenishInfo} = this.state;
    const {
      validate_buyfrom,
      validate_buyproof,
      validate_firstname,
      validate_lastname,
      validate_phone,
      validate_serialnumber,
      uploadFailedText,
      fileTypeErrText,
      serialNumberErrorText,
      lastname_placeholder,
      firstname_placeholder,
      phone_placeholder,
      serial_number_placeholder,
      buyfrom_placeholder,
    } = blok;

    return (
     <>
      {/* 前缀名字 后缀名字 */}
      <div className={`product_register_newform_name_box ${ needReplenishInfo ? 'product-btn-no-show' : ''}`}>
        <div className="fifty product_register_newform_surname_box">
          <label htmlFor="lastname">
            <div>
              <span className="show modal_table_input_title"> 
                {t('profile.lastNamePlaceHolder', this.lang)}
              </span>
            </div>
            <input 
              name="lastname" 
              type="text" 
              maxLength="255"
              className="modal_table_input"
              placeholder={ lastname_placeholder } 
              value={user.lastname} 
              onChange={this.handleUserChange.bind(this)}
              onBlur={this.handleUserChange.bind(this)}
            />
            <div className="modal_table_error_tip" style={{ display: this.state.errors.lastname ? 'block' : 'none'}}>{validate_lastname}</div>
          </label> 
        </div>
        <div className="fifty product_register_newform_firstname_box">
          <label htmlFor="firstname">
            <div>
              <span className="show modal_table_input_title"> 
                {t('profile.firstNamePlaceHolder', this.lang)}
              </span>
            </div>
            <input 
              name="firstname" 
              id="firstname" 
              type="text" 
              maxLength="255"
              className="modal_table_input"
              placeholder={ firstname_placeholder } 
              value={user.firstname}
              onChange={this.handleUserChange.bind(this)}
              onBlur={this.handleUserChange.bind(this)}
            />
            <div className="modal_table_error_tip" style={{ display: this.state.errors.firstname ? 'block' : 'none'}}>{validate_firstname}</div>
          </label> 
        </div>
      </div>

      {/* 联系电话 */}
      <label htmlFor="phone" className={`${ needReplenishInfo ? 'product-btn-no-show' : '' }`}>
        <div>
          <span className="show modal_table_input_title"> 
              {t('profile.phonePlaceHolder', this.lang)}
          </span>
        </div>
        <input
          name="phone"
          type="text"
          maxLength="255"
          className="modal_table_input_nubmer"
          placeholder={ phone_placeholder } 
          value={user.phone} 
          onChange={this.handleUserChange.bind(this)}
          onBlur={this.handleUserChange.bind(this)}
        />
        <div className="modal_table_error_tip" style={{ display: this.state.errors.phone ? 'block' : 'none'}}>{validate_phone}</div>
      </label>

      {/* 序列号 */}
      <label htmlFor="newModelSerial">
        <div className={ needReplenishInfo ? 'modal_table_select_products_replenish_margin_sn' : '' }>
          <span className="show modal_table_input_title">
            {t("profile.product.serial_number", this.lang)}
            <div className="product_register_newform_serial_number_show_img_box">
              <img className="product_register_newform_serial_number_show_img" src="/assets/icons/info-circle.svg" />
              <img className="product_register_newform_serial_number_tooltiptext" src={serial_nubmer_image?.filename} />
            </div>
          </span>
        </div>
        <input
          disabled={needReplenishInfo}
          name="newModelSerial"
          className={`${this.state.errors.serial ? "error" : null} modal_table_input_nubmer ${needReplenishInfo ? 'modal_table_select_products_replenish modal_table_select_products_replenish_sn' : ''}`}
          type="text"
          maxLength="255"
          placeholder={ serial_number_placeholder }
          value={this.state.newModelSerial}
          onChange={this.handle.bind(this)}
          onBlur={this.handleKeyupCheckserial.bind(this)}
          onKeyUp={this.handleKeyupCheckserial.bind(this)}
          onFocus = {this.handlerRequireFocus.bind(this)}
        />
        <div className="modal_table_error_tip" style={{ display: this.state.errors.serial ? 'block' : 'none'}}>{validate_serialnumber}</div>
        <div style={{ color: '#FF0000', margin: '0px', display: this.state.serialNumberError && !this.state.errors.serial ? 'flex' : 'none', width: '100%', fontSize:'12px', fontFamily: 'Tw Cen'}}>{serialNumberErrorText}</div>
      </label>

      {/* 购买渠道 购买日期 */}
      <div className={`product_register_newform_name_box ${ needReplenishInfo ? 'modal_table_select_products_replenish_margin' : ''}`}>
        <div className="fifty product_register_newform_lastname_box">
          <label htmlFor="buyfrom">
            <div>
              <span className="show modal_table_input_title"> 
                {t('profile.products.buyfrom', this.lang)}
              </span>
            </div>
            <input 
              name="buyfrom" 
              type="text" 
              maxLength="255"
              className="modal_table_input"
              placeholder={ buyfrom_placeholder } 
              value={buyfrom} 
              onChange={this.handle.bind(this)}
              onBlur={this.handleCheckBuyFrom.bind(this)}
              onKeyUp={this.handleCheckBuyFrom.bind(this)}
              onFocus = {this.handlerRequireFocus.bind(this)}
            />
            <div className="modal_table_error_tip" style={{ display: this.state.errors.buyfrom ? 'block' : 'none'}}>{validate_buyfrom}</div>
          </label> 
        </div>
        <div className="fifty product_register_newform_firstname_box">
          <label htmlFor="purchaseDate">
            <div className="modal_table_input_title">{t("profile.products.purchaseDate", this.lang)}</div>
            <DatePicker
              className="modal_table_input_nubmer"
              selected={this.state.newModelDate}
              onChange={(date) => this.setState({ newModelDate: date })}
              onFocus={() => this.handleDateFocus()}
              maxDate={new Date()}
              name="purchaseDate"
              calendarClassName="calendar"
            />
          </label>
        </div>
      </div>

      {/* 购买凭证图片上传 */}
      <label htmlFor="buyproofImage">
        <div>
          <span className="show modal_table_input_title margin_upload_file">
            {t("profile.products.buyproof", this.lang)}
          </span>
          <div className="modal_table_upload_file_desc">{blok?.uploadFileDesc}</div>
        </div>
        <div className="product_register_newform_previewImage_flex">
          {
            this.state.buyproofImage?.map((item) => (
              <div className="product_register_newform_previewImage_box" key={item.objectKey}>
                <img className="product_register_newform_previewImage" src={item.url} alt="item.name" />
                <div className="product_register_newform_previewImage_close_btn" onClick={this.handleDeleteImg.bind(this, item.objectKey)}>
                  <img className="product_register_newform_previewImage_close_btn_img" src="/assets/icons/close-white.svg" />
                </div>
              </div>
            ))
          }
          {
            // 上传中且非上传失败
            this.state.uploadingFile && !this.state.uploadErr ? (
              <div className="product_register_newform_previewImage_uploading">
                <img src="/assets/icons/loading-indicator.svg" style={{ width: '20%' }} />
              </div>)  : null
          }
          <div className="product_register_newform_file_upload_btn_box" style={(this.state.fileUploadDisabled || this.state.isLastImage) ? { display: 'none'} : null}>
            <input
              ref={(elm) => { this.imgRef = elm; }}
              name="buyproofImage"
              type="file"
              accept="image/jpeg, image/png"
              required
              multiple
              className="product_register_newform_previewImage_input"
              disabled={ this.state.uploadingFile} // loading的时候禁用
              style={this.state.uploadingFile ? {cursor: 'not-allowed'} : null}
              placeholder={t("profile.products.buyproof", this.lang)}
              onChange={this.handleFileChange.bind(this)}
              onMouseOver={this.handleMouseOver.bind(this)}
              onMouseOut={this.handleMouseOut.bind(this)}
              onBlur={this.handleCheckFile.bind(this)}
              onFocus = {this.handlerRequireFocus.bind(this)}
              onKeyUp={this.handleCheckFile.bind(this)}
            />
            <div className={(this.state.mouseOver && !this.state.uploadingFile && !this.state.isM) ? 'product_register_newform_previewImage_svg_hover' : 'product_register_newform_previewImage_svg'}>
            </div>
          </div>
        </div>
        {
          // 上传失败且非上传中
          this.state.uploadErr && !this.state.uploadingFile ? <div style={{color:'#FF0000', margin:'0px'}}>{uploadFailedText}</div> : null
        }
        <div className="modal_table_error_tip" style={{ display: this.state.errors.buyproof && !this.state.uploadErr && !this.state.uploadingFile && !this.isBuyProofImageMatch()  ? 'block' : 'none'}}>{validate_buyproof}</div>
        <div className="modal_table_error_tip" style={{ display: this.state.fileTypeErr && !this.state.uploadingFile && !this.state.uploadErr ? 'block' : 'none'}}>{fileTypeErrText}</div>
      </label>
      <hr></hr>
     </>
    )
  };

  // 其他产品 旧表单
  renderOldForm = () => {
    return (
      <>
        <label htmlFor="purchaseDate">
          <div className={this.state.isZhCn ? "modal_table_input_title" : "" }>{t("profile.products.purchaseDate", this.lang)}</div>
          <DatePicker
            className={this.state.isZhCn ? "modal_table_input" : ""}
            selected={this.state.newModelDate}
            onFocus={() => this.handleDateFocus()}
            onChange={(date) => this.setState({ newModelDate: date })}
            maxDate={new Date()}
            name="purchaseDate"
            calendarClassName="calendar"
          />
        </label>
        <label htmlFor="newModelSerial">
          <div>
            <span className={this.state.isZhCn ? "show modal_table_input_title" : "show" }>
              {t("profile.product.serial_number", this.lang)}
            </span>
          </div>
          <input
            name="newModelSerial"
            className={`${this.state.errors.oldFormSerial ? "error" : null} ${this.state.isZhCn ? "modal_table_input" : null}`}
            type="text"
            placeholder={t("profile.product.serial_number", this.lang)}
            value={this.state.newModelSerial}
            onChange={this.handle.bind(this)}
            onBlur={this.handle.bind(this)}
          />
        </label>
      </>
     )
  }

  // 渲染唯一select x2d100c
  renderOnlySelect = () => {
    const { models } = this.state;
    return (
      <select
        name="newModelId"
        disabled
        className="modal_table_select_products modal_table_select_products_replenish modal_table_select_products_replenish_model modal_table_select_products_replenish_sn"
        value={this.state.newModelId}
        onChange={this.handleInputChange.bind(this)}
        onBlur={this.handleInputChange.bind(this)}
      >
        {
          models.map((group) => (
            <optgroup key={group.name} label={group.name}>
              {group.products.map((product, key) => {
                if (product.id == 162) {
                  return (
                    <option value={product.id} key={key}>
                      {product.name}
                    </option>
                  )
                }
              })}
            </optgroup>
          ))
        }
      </select>
    )
  }

  // 渲染正常的select框
  renderNormalSelect = () => {
    const { models } = this.state;

    return (
      <select
        name="newModelId"
        className={ this.state.isZhCn ? "modal_table_select_products" : "" }
        value={this.state.newModelId}
        onChange={this.handleInputChange.bind(this)}
        onBlur={this.handleInputChange.bind(this)}
      >
        <option
          disabled="disabled"
          value=""
          defaultChecked={true}
        >
          {t("profile.selectProduct", this.props.lang)}
        </option>
        {
           models.map((group) => (
            <optgroup key={group.name} label={group.name}>
              {group.products.map((product, key) => (
                <option value={product.id} key={key}>
                  {product.name}
                </option>
                )
              )}
            </optgroup>
          ))
        }
      </select>
    )
  }

  render = () => {
    const { blok, lang } = this.props;
    const {
      products,
      getProductStatus,
      errors,
      isLoading,
      isDone,
      clickbtn,
      isM,
      newTableFlag,
      canSubmit,
      uploadingFile,
      isReplenishInfo,
      isZhCn,
      needReplenishInfo,
      buyproofImage,
    } = this.state;
    const { 
      sorry, 
      sorryDesc, 
      alreadyRegistered, 
      alreadyRegisteredDesc, 
      thanksForYourRegister, 
      thanksForYourRegisterDesc, 
      serialNubmerImage, 
      serialNubmerImageMobile, 
      update_fail_title,     
      update_fail_desc,     
      update_success_title,     
      update_success_desc,  
      replenishBtnText,
      tableReplenishBtnText,   
    } = blok;
    let { serial, model, buyfrom, buyproof, oldFormSerial} = errors;
    // 如果是补充信息 则修改对应的error状态值为默认正确
    if (needReplenishInfo) {
      model = false;
      canSubmit.buyproofImage = true;
      canSubmit.buyfrom = true;
      canSubmit.newModelSerial = true;
    }
    /**
     * 表单允许提交的条件:
     * 1. 需要有两个附件(购买凭证 & 产品序列图)
     */
    const submitBtnDisabledNewTable = !serial && !model && !buyfrom && !buyproof && canSubmit.buyproofImage && canSubmit.buyfrom && canSubmit.newModelSerial && !uploadingFile && buyproofImage.length > 1;
    let submitBtnDisabled = newTableFlag ? submitBtnDisabledNewTable : (!oldFormSerial && !model);

    const serial_nubmer_image = isM ? serialNubmerImageMobile : serialNubmerImage;
    let newTableTitle = '';
    let newTableDesc = '';
    // X2D100C thankyou页面字段
    if(this.state.chinaCommodity && !this.state.hasBeenRegistered) {
      newTableTitle = thanksForYourRegister;
      newTableDesc = thanksForYourRegisterDesc;
    } else if(!this.state.chinaCommodity && !this.state.hasBeenRegistered){
      newTableTitle = sorry;
      newTableDesc = sorryDesc;
    } else if(this.state.hasBeenRegistered){
      newTableTitle = alreadyRegistered;
      newTableDesc = alreadyRegisteredDesc;
    }
    if (this.state.updateProductSuccess && this.state.needReplenishInfo) {
      newTableTitle = update_success_title;
      newTableDesc = update_success_desc;
    } else if (this.state.needReplenishInfo) {
      newTableTitle = update_fail_title;
      newTableDesc = update_fail_desc;
    }

    return (
      <SbEditable content={blok}>
        <div className="editorial column product_block_box">
          <div className="et left hundred black registerMobile">
            <h1 className={`span-12 ${this.state.isZhCn ? 'register_title_mobile' : ''}`}>{t("profile.products.title", lang)}</h1>
            <p className={`span-9 ${this.state.isZhCn ? 'register_desc_mobile' : ''}`}>{t("profile.products.description", lang)}</p>
            <div className="cta-row">
              <button className={`cta ${this.state.isZhCn ? 'registerMobile-button' : ''}`} onClick={this.handleOpenModal}>
                {t("profile.products.addProduct", lang)}
              </button>
            </div>
          </div>

          <Modal
            isOpen={this.state.showModal}
            style={{ outline: 0 }}
            overlayClassName="button-play-infomation-overlay-product"
            className="button-play-infomation-modal-product"
            shouldCloseOnOverlayClick={true}
            shouldFocusAfterRender={false}
            preventScroll={false}
            shouldReturnFocusAfterClose={false}
            bodyOpenClassName="button-play-infomation-open-body"
            htmlOpenClassName="button-play-infomation-open-html"
            contentRef={node=>{this.contentRef=node}}
            ariaHideApp={false}
            onAfterOpen={()=>{this.handleAfterOpen()}}
          >
            <button className="infomation-modal-closs-outsideBox" onClick={this.handleCloseModal}>
              <div className="infomation-modal-closs"></div>
            </button>
            <div className="modal__content editorial column">
              <div className="et center fifty modal_table_title_padding" style={{ display: ((this.state.newTableFlag && this.state.thankYou) || needReplenishInfo) ? 'none' : 'block' }}>
                <h1 className={ this.state.isZhCn ? "modal_table_title" : ""}>{t("profile.products.addProduct", lang)}</h1>
              </div>
              <div
                className="et center fifty mobileModePadding replenish-pc-padding"
                style={{ display: this.state.thankYou ? "none" : "block" }}
              >
                <div>
                  <label htmlFor="newModelId">
                    <div className={ this.state.isZhCn ? "modal_table_input_title" : "" }>{t("profile.product.selectModel", lang)}</div>
                    {
                       needReplenishInfo ? this.renderOnlySelect() : this.renderNormalSelect()
                     }
                  </label>
                 {
                   this.state.newTableFlag ? this.renderNewForm(serial_nubmer_image, blok) : this.renderOldForm()
                 }
                  <div className="cta-row">
                    <button
                      className={` cta ${
                        submitBtnDisabled ? null : "disabled"
                      } ${isLoading ? "loading" : null} ${
                        isDone ? "done" : null
                      } ${this.state.isZhCn ? "modal_button_submit" : "" }`}
                      disabled={!submitBtnDisabled}
                      onClick={ needReplenishInfo ? this.updateProcut : this.addProduct}
                    >
                      { needReplenishInfo ? tableReplenishBtnText : t("profile.products.addProduct", lang)}
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="et center fifty"
                style={{ display: this.state.thankYou && !this.state.newTableFlag ? "block" : "none" }}
              >
                <h2>{t("profile.products.thankYou", lang)}</h2>
                <p>{t("profile.products.thankYouDescription", lang)}</p>
                <div className="cta-row">
                  <button className="cta" onClick={this.addNewProduct}>
                    {t("profile.products.addMoreProducts", lang)}
                  </button>
                </div>
              </div>
              
              {/* 新表单成功提示样式 */}
              <div 
                className="newtable_thank_you_outsizebox"
                style={{ display: this.state.thankYou && this.state.newTableFlag ? "block" : "none" }}
              >
                <img className="newtable_thank_you_img" src={this.state.chinaCommodity || (this.state.updateProductSuccess && needReplenishInfo) ? "/assets/icons/upload-success.svg" : "/assets/icons/upload-warning.svg"} />
                <h1 className="newtable_thank_you_title">{newTableTitle}</h1>
                <p className="newtable_thank_you_desc" dangerouslySetInnerHTML={MarkDown(newTableDesc)}></p>
                <button className="cta newtable_thank_you_cta" onClick={this.handleCloseModal}>{t("profile.button.goback", lang)}</button>
              </div>
            </div>
          </Modal>
        </div>
        <article
          className="table"
          style={{ display: products.length > 0 ? "table" : "none" }}
        >
          <div className="rowHeading">
            <div className="col">
              <h4>{t("profile.products.product", lang)}</h4>
            </div>
            <div className="col">
              <h4>{t("profile.products.serial", lang)}</h4>
            </div>
            <div className="col">
              <h4>{t("profile.products.purchaseDate", lang)}</h4>
            </div>
            <div className="col">
              <h4></h4>
            </div>
            <div className="col"></div>
          </div>
          {products.map((p,index) => {
            const showNewBtn = isZhCn && p.product_id === 162;
            const replenish = isReplenishInfo[index];

            return (
              <div className="row" key={p.id}>
                <div className={`col ${this.state.isZhCn ? 'register_mobile_item' : ''}`}>
                  <h4>{t("profile.products.product", lang)}</h4>
                  <p>{p.name}</p>
                </div>
                <div className={`col ${this.state.isZhCn ? 'register_mobile_item' : ''}`}>
                  <h4>{t("profile.products.serial", lang)}</h4>{" "}
                  <p>{p.serial_number}</p>
                </div>
                <div className={`col ${this.state.isZhCn ? 'register_mobile_item' : ''}`}>
                  <h4>{t("profile.products.purchaseDate", lang)}</h4>{" "}
                  <p>{this.getLocalDate(p.purchased)}</p>
                </div>
                <div className={`col registerSuccessTest ${this.state.isZhCn ? 'register_mobile_item' : ''}`} style={{ color: "#D95E00" }}>
                  {
                    (this.state.isZhCn && p.extended_warranty == 1) ? (<p dangerouslySetInnerHTML={MarkDown(blok?.registerSuccessTest)}></p>) : ''
                  }
                  {/* <h4>&nbsp;</h4> <p><a href={"/downloads?product=" + p.product_id } class="">{ t('profile.products.toDownload', lang )}</a></p> */}
                </div>
                <div className={`col ${this.state.isZhCn ? 'register_mobile_item' : ''}`}>
                  <p>
                    <button
                      className={`cta ${isLoading && (clickbtn === index)? "loading" : null} ${ showNewBtn ? 'product-btn-no-show' : '' } `}
                      onClick={(e) => this.removeProduct(p.id, e, index)}
                      disabled={isLoading}
                    >
                      {t("profile.products.removeProduct", lang)}
                    </button>
                    <button
                      className={`cta ${ showNewBtn && replenish ? '' : 'product-btn-no-show' }`}
                      onClick={(e) => this.replenishProduct(p, e, index)}
                      disabled={isLoading}
                    >
                      {replenishBtnText}
                    </button>
                  </p>
                </div>
              </div>
            )
          })}
        </article>
        <div
          className="editorial column"
          style={{  display: getProductStatus=='finished' && products.length === 0 ? "flex" : "none" }}
        >
          <div className="et left hundred black">
            <h3>{blok.no_product_text}</h3>
          </div>
        </div>
        <div
        style={{  display: getProductStatus=='loading'? "block" : "none" }}
        >
          <div className="product-loading">
            <img src="/assets/icons/loading-indicator.svg" alt="Loading" />
          </div>
        </div>
        <div
        className="editorial column"
        style={{  display: getProductStatus=='failed'? "flex" : "none" } }
        >
          <div className="et left hundred black">
            <h3>{blok.load_failed_text}</h3>
          </div>
        </div>
      </SbEditable>
    );
  };
}

export default ProductBlock;
