import React, { useState, useLayoutEffect, useRef } from "react";
import SbEditable from "storyblok-react";
import Helmet from "react-helmet";
import MarkDown from '../../markdown'
import Button from '../../button';
import ButtonWatchVideo from '../../button_watch_video'
import IconDescSec from '../IconDescSection'
import { DeviceInfo } from '../../../../hooks/device';
import {
  styles, hasDocument
} from '../../../../utils/helper'
import "./style.scss";

const Banner = (props) => {
  const { blok, lang } = props;
  const {
    title,
    bgImage,
    mobileBgImage,
    subTitle,
    desc,
    watchVideoBtn = [],
    iconDescSec = [],
    pcTitleTop,
    mobileTitleTop,
    pcTitlePaddingTop,
    mobileTitlePaddingTop,
    pcSubSecTop,
    mobileSubSecTop,
    pcSubSecBottom,
    mobileSubSecBottom,
    imgHeight,
    imgHeightMobile,
    textContentMaxWidth, // 文本内容最大宽度
    revertPictureAndTitle, // 倒转标题和产品图的位置
    pcSubtitleStyles,
    mobileSubtitleStyles,
    pcWrapperStyles,
    mobileWrapperStyles,
    foreImageOff, // 关闭前景图
    bgImageOn, // 开启组件背景图
    pictureAsTitleHiddenText, // 大师赛图片标题隐藏的索引标题
    pictureAsTitle, // 大师赛图片标题（pc端）
    mobilePictureAsTitle, // 大师赛图片标题（m端）
    pictureAsTitleWidth, // 大师赛图片标题宽度（pc端）
    mobilePictureAsTitleWidth, // 大师赛图片标题宽度（m端）
  } = blok;

  const [mounted, setIsMounted] = useState(false);
  useLayoutEffect(() => {
    if (!hasDocument) return;
    setIsMounted(true);
  })

  const { isMobile: isM } = DeviceInfo();
  const titleTop = isM ? mobileTitleTop : pcTitleTop;
  const subSecTop = isM ? mobileSubSecTop : pcSubSecTop;
  const subSecBottom = isM ? mobileSubSecBottom : pcSubSecBottom;
  const backgroundImage = isM ? mobileBgImage : bgImage;
  const customSubtitleStyle = isM ? styles(mobileSubtitleStyles) : styles(pcSubtitleStyles);
  const wrapperStyle = isM ? styles(mobileWrapperStyles) : styles(pcWrapperStyles);
  const wrapperBgStyle = mounted && bgImageOn && backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {};
  const pictureAsTitleImgSrc = pictureAsTitle && pictureAsTitle.filename;
  const mobilePictureAsTitleImgSrc = mobilePictureAsTitle && mobilePictureAsTitle.filename;

  const MainPicture = (!foreImageOff && (mobileBgImage || bgImage)) ? (
    <picture>
      <source media="(max-width: 767px)" srcSet={ mobileBgImage || bgImage } />
      <source media="(min-width: 768px)" srcSet={bgImage} />
      <img className="image" src={backgroundImage} style={{height: !isM ? (imgHeight || '70vw') : (imgHeightMobile || '75vw')}} alt="" />
    </picture>
  ) : null;
  // 虽然 titleTop 配置类型为 Number，但实际 typeof titleTop 为 string，导致配置 0 时会产生多余的 marginTop
  const MainTitle = (
    <div className="title" style={{top: titleTop ? titleTop + 'px' : null, paddingTop: isM ? mobileTitlePaddingTop : pcTitlePaddingTop, marginTop: isM ? (titleTop && titleTop !== '0' ? Number(titleTop) + 16 + 'px' : null) : null}}>
      <h3 dangerouslySetInnerHTML={ MarkDown(title) }></h3>
    </div>
  );
  const pictureAsTitleContent = (
    <div className="picture-as-title-wrapper">
      {pictureAsTitleHiddenText && <h1 className="picture-as-title-hidden-text">{pictureAsTitleHiddenText}</h1>}
      {pictureAsTitleImgSrc && <img className="picture-as-title-image" src={pictureAsTitleImgSrc} style={{ width: pictureAsTitleWidth || '590px' }} alt="" />}
      {mobilePictureAsTitleImgSrc && <img className="picture-as-title-mobile-image" src={mobilePictureAsTitleImgSrc} style={{ width: mobilePictureAsTitleWidth || '255px'}} alt="" />}
    </div>
  );
  return (
    <>
      <Helmet>
        { isM ? 
          <link rel="preload" as="image" href={mobileBgImage || ''} />
          : <link rel="preload" as="image" href={bgImage || ''} />
        }
        {pictureAsTitleImgSrc && <link rel="preload" as="image" href={pictureAsTitleImgSrc} />}
        {mobilePictureAsTitleImgSrc && <link rel="preload" as="image" href={mobilePictureAsTitleImgSrc} />}
      </Helmet>
      <SbEditable content={blok}>
        <div className="ec1706-banner-wrapper">
          <div className="banner-bg-sec-wrapper" style={{ ...wrapperStyle, ...wrapperBgStyle }}>
            <div className="main-sec-wrapper" style={{ overflow: foreImageOff ? 'initial' : 'hidden' }}>
              {revertPictureAndTitle ? [MainTitle, MainPicture] : [MainPicture, MainTitle]}
            </div>
            <div className="sub-sec-wrapper" style={{ paddingTop: subSecTop, paddingBottom: subSecBottom, ...(textContentMaxWidth ? { maxWidth: textContentMaxWidth } : {}) }}>
              {pictureAsTitleContent}
              {subTitle && <div className="sub-title" dangerouslySetInnerHTML={ MarkDown(subTitle) } style={customSubtitleStyle}></div>}
              <div className="desc" dangerouslySetInnerHTML={ MarkDown(desc) } ></div>
              <div className="watch-video-btn">
                {
                  watchVideoBtn && watchVideoBtn.length > 0 && watchVideoBtn.map((item, index) => item && item.component === 'button_link' ? (
                    <Button content={item} key={index} lang={lang} />
                  ): (
                    <ButtonWatchVideo content={item} key={index} lang={lang} />
                  ))
                }
              </div>
            </div>
            <IconDescSec content={iconDescSec} maxWidth={textContentMaxWidth} />
          </div>
        </div>
      </SbEditable>
    </>
  )
}

export default Banner;
